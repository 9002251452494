.App-logo {
    animation: App-logo-spin infinite 20s linear;
    height: 80px;
}

@keyframes App-logo-spin {
    from { transform: rotate(0deg); }
    to { transform: rotate(360deg); }
}

.App-title {
    font-size: 1.5rem;
}

.App-header {
    background-color: #222;
    height: 150px;
    padding: 20px;
    color: white;
    text-align: center;
}

.AuthHome {
    background-color: #FAFAE2;
    height: 100%;
    width: 100%;
}

.ButtonBack {
    background-color: #FAFAE2;
}

body {
    font-family: 'Roboto', sans-serif;
    background-color: #FAFAE2;
    height: 50rem;
    color: #FAFAE2;
}
.homeButton {
    background-color: #7F5539;
    border: none;
    color: white;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 1.30rem;
    margin-top: 1rem;
    border-radius: 5px;
    cursor: pointer;
    width: 100%;
    height: 3rem;
    border-radius: 3rem;
    transition: background-color 0.3s ease;
}

.homeButton:hover {
    background-color: #8B4513; /* Darker background color on hover */
}

.footer {
    background-color: #FAFAE2;
    height: 100%;
}
.footerHomeDog1img{
    width: 12rem;
}

.footerHomeDog2img{
    width: 16rem;
    margin-top: 4rem;
}
.footerBackGroudLogo{
    width: 25rem;
}

.customHeader{
    background-color: white;
    border-radius: 26rem;
    width: 100% !important;

}

.CustomNav{
    gap: 5rem;
}

.CustomNav .nav-link.active {
    background-color:  #FAFAE2 !important;
    border-color: #FAFAE2 !important;
    color: black !important;
}

.CustomNav .nav-link {
    background-color:  #FAFAE2 !important;
    border-color: #FAFAE2 !important;
    color:  #1877F2 !important;

}

.form-container {
    background-color: white;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.card-img-top{
    padding: 1rem;
    border-radius: 2.5rem !important;
    margin-bottom: -1rem !important;
    height: 12rem;
}

.card {
    width: 19rem;
    border-radius: 1rem !important;
    height: 31rem;
}
.card-text{
    font-size: medium;
}

.btn-outline-primary {
    border-color: #8B4513 !important;
    color: #8B4513 !important;
}
.btn-outline-primary:hover{
    background-color: white !important;
}

.btn-primary{
    background-color: #8B4513 !important;
    border-color: #8B4513 !important;
}

.bi-pencil::before{
    content: "\f4cb" !important;
    padding: 0.75rem !important;
    background: #8B4513 !important;
    border-radius: 20% !important;
    color: #F5F5CB !important;
}

.generalInfoContainer{
    border-radius: 8px;
    background: #FFF;
    box-shadow: 2px 2px 2px 2px rgba(0, 0, 0, 0.10);
}

.photoGallery{
    border-radius: 8px;
    background: #FFF;
    box-shadow: 2px 2px 2px 2px rgba(0, 0, 0, 0.10);
}

.boxGeneralInfo{
    height: 31rem;
}

.boxPhoto{
    height: 36rem;
}

.popup-content {
    max-width: 90vw; /* Ensures the popup is not too wide on small screens */
    max-height: 90vh; /* Ensures the popup is not too tall on small screens */
    overflow-y: auto; /* Allows content inside to scroll if necessary */
    overflow-x: hidden;
    padding: 20px;
    background-color: white;
    border-radius: 10px;
}

.seeAllCopy{
    text-decoration: none;
}

.overlayStyle {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
    overflow: hidden;
}

.optionSelected{
    display: none;
}
.cpoyOwnerGray{
    color: #65676B;
}

.addressBox{
    position: absolute;
    top: 32%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: white;
    color: #1877F2;
    padding: 0.5rem 1rem;
    border-radius: 8px;
    text-align: center;
    z-index: 1;
    pointer-events: none;
    width: 78% !important;
}
.inputFile{
    margin-left: 0.5rem;
}

.customPaginator{

    --bs-pagination-active-bg: #8B4513 !important;
    --bs-pagination-active-border-color: #8B4513 !important;
    --bs-pagination-focus-box-shadow: none !important;
    --bs-pagination-color:#8B4513 !important;
    --bs-pagination-hover-color: #8B4513 !important;
}




.form-check-input{
    background-color: #8B4513 !important;
    border-color: #7F5539 !important;
}

.customCheck{
    margin-left: -13px!important;
}

.form-check{
    border: 0.5px solid #F2F2F2 !important;
}

.filterSection{
    background-color: #FFFFFF;
}

/* Hide Leaflet control pane */
.custom-map-container .leaflet-control-attribution {
    display: none;
}

.custom-map-container .leaflet-control-zoom {
    display: none;
}

.custom-map-container .leaflet-control-scale {
    display: none;
}

.custom-map-container .leaflet-control-layers {
    display: none;
}

.customBadge{
    height: 2rem !important;
    width: 6rem !important;
    border-radius: 20px !important;
    font-size: larger !important;
}

.customPrimaryBadgeBg{
    background-color: #154c79 !important; ;
}

@media screen and (max-width: 820px) {
    .footerHomeDog2img {
        display: none;
    }
}

@media screen and (max-width: 1394px) {
    .CustomNav  {
        gap: 0rem;
    }
}


.overlay {
    position: fixed;
    top: 0;
    right: 0;
    width: 45%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.6);
    z-index: 1050;
    display: flex;
    align-items: center;
    justify-content: center;
}

.overlay .nav-link {
    color: white;
    font-size: 1.5rem;
    margin: 0.5rem 0;
}


.customInsetDog{
    width: 6rem !important;
}

.mainLoginBg{
    background-color: white;
    max-width: 100%;
}

.loginButton{
    width: 100%;
}

.loginImg{
    width: 100%;
}
